// fa
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// slick
$slick-font-family: "swap-slick";
$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import "~slick-carousel/slick/slick-theme.scss";
@import "~slick-carousel/slick/slick.scss";
/* self-load this */
@font-face {
  font-family: "swap-slick";
  src: slick-font-url("slick.eot");
  src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import "flatpickr/dist/flatpickr.css";
